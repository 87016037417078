import React from 'react';
import {connect} from 'react-redux';
import queryString from 'query-string';

import './styles.scss';
import Logo from 'assets/logo-dysmate.png';
import CustomLogin from '../CustomLogin';
import {initAuthAction} from 'actions/index';
import strings from '../../utils/strings';
import {authCallback} from 'utils/bankIdLogin';

import {
	BANK_ID,
	BANK_ID_MOBILE,
	FEIDE,
	IS_NORMING_VERSION,
} from 'constants/index';

import loginNoAuth from 'api/loginNoAuth';
import getTestKeyInfo from 'api/getTestKeyInfo';
import CustomPinLogin from 'pages/CustomPinLogin';
const parsedSearch = queryString.parse(window.location.search);
const search = parsedSearch;
const params = new URLSearchParams(search);

const testKey = parsedSearch.key;
const authSuccess = parsedSearch.authSuccess;
const id = params.get('id');
/* const flag = JJiisTezRREXXDSSERllliOR321Frrsaas */

function IsValidJSONString(str) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}
class Login extends React.Component {
	constructor() {
		super();

		this.state = {
			email: null,
			pinCode: null,
			userPin: null,
			isLoginVisible: true,
			passwordLogin: false,
			isFeide: false,
			email: null,
			pinCode: null,
			childSelfStarting: null,
			showCoockieDetectionFrame: false,
			showAltLogin:
				/* id === 'JJiisTezRREXXDSSERllliOR321Frrsaas' || */
				id === 'child-exp-oms-test' ? true : false,
			altError: null,
			instructions: null,
			requiresVerification: false,
			initialPin: null,
			dispatchAddress: null,
			testStatus: null,
			pinLogin: true,
			isDistributed: false,
		};
	}

	componentDidMount() {
		window.localStorage.removeItem('authtoken-screening');

		if (!testKey) {
			window.location.href = `/?error=true`;
			return;
		}
		getTestKeyInfo(testKey)
			.then((response) => {
				const email = response.email || response.dispatchAddress;
				const pinCode = response.initialPin;
				const requiresVerification = response.requiresVerification;
				const initialPin = response.initialPin;
				const dispatchAddress = response.dispatchAddress;
				const isDistributed = response.isDistributed;

				let instructionsText =
					response.status !== 'isCompleted' &&
					response.requiresVerification ? (
						<span>
							Denne testen er eldre enn en måned. For å sikre ditt
							personvern må vi bekrefte at det er riktig bruker
							starter testen. Vennligst bruk pinkoden som vi har
							sendt til{' '}
							<span style={{color: 'white'}}>
								<i>
									<u>{response.dispatchAddress}</u>
								</i>
							</span>{' '}
							for å starte testen.
						</span>
					) : null;
				if (response.status === 'isUsed') {
					instructionsText = (
						<span>
							Denne testen er startet før, men ikke fullført. For
							å sikre ditt personvern må vi bekrefte at det er
							riktig bruker starter testen. Vennligst bruk
							pinkoden som vi har sendt til{' '}
							<span style={{color: 'white'}}>
								<i>
									<u>{response.dispatchAddress}</u>
								</i>
							</span>{' '}
							for å starte testen.
						</span>
					);
				}
				const errorToThrow =
					response.status === 'isCompleted'
						? 'Denne testen er allerede fulført'
						: null;
				this.setState({
					email,
					pinCode,
					requiresVerification: false,
					pinLogin: instructionsText ? true : false,
					initialPin,
					dispatchAddress,
					altError: errorToThrow,
					instructions: instructionsText,
					isDistributed: Boolean(isDistributed),
				});
			})
			.catch((response) => {
				response.json().then((error) => {
					if (error.error === 'pincode instead') {
						this.setState({
							altError:
								'Det ser ut til at du bruker pinkode i stedet for testnøkkel for å starte testen.',
						});
					} else {
						this.setState({
							altError:
								'Testnøkkelen er ikke gyldig eller allerede brukt.',
						});
					}
				});
			});

		var receiveMessage = function (evt) {
			if (evt.data === 'MM:3PCunsupported') {
				/* document.getElementById(
					'start-fail-warn-ing'
				).innerHTML = `<img src=${warning} width="40px" height="30px">`; */
				document.getElementById('lit-site-warning').innerHTML =
					strings.warning2;
				document.getElementById('lit-site-warning_text').innerHTML =
					strings.warning2text;
				document.getElementById(
					'lit-site-settings-instructions'
				).innerHTML = strings.linkForInstructions;
				document.getElementById('lit-site-warning_text_end').innerHTML =
					strings.warning_text_end;
			} else if (evt.data === 'MM:3PCsupported') {
				document.getElementById('result').innerHTML = '';
			}
		};
		window.addEventListener('message', receiveMessage, false);

		if (authSuccess) {
			let data = atob(
				decodeURIComponent(
					window.location.search.substr(
						window.location.search.indexOf('=') + 1
					)
				)
			);

			if (data && IsValidJSONString(data)) {
				return new Promise((resolve, reject) => {
					data = JSON.parse(data);

					if (data.authError) {
						reject({message: 'There was an error logging you in'});
						return;
					}

					if (
						data &&
						data.action === 'auth' &&
						data.result === 'OK'
					) {
						localStorage.setItem(
							'authtoken-screening',
							data.authToken
						);

						window.location.href = `/test`;
						resolve();
						return;
					}
					reject({message: `Kunne ikke logge inn!`});
				});
			} else {
				return;
			}
		}
	}

	initializeAltLogin = () => {
		this.setState({
			showAltLogin: true,
		});
	};
	onAltLoginFieldChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};
	submitAltLogin = (e) => {
		e.preventDefault();
		const {firstName, lastName} = this.state;
		const data = {testKey, firstName, lastName};

		loginNoAuth(data)
			.then((results) => {
				authCallback({data: JSON.stringify(results)});
			})
			.catch(() => {
				this.setState({
					altError: 'Ikke gyldig testkode',
				});
			});
	};

	comparePinCodes() {
		if (
			this.state.initialPin.toLowerCase() ===
			this.state.userPin.toLowerCase()
		) {
			this.setState({
				requiresVerification: false,
				instructions: null,
			});
		} else {
			this.setState({
				altError: 'Feil pinkode. Testen kan ikke startes',
			});
		}
	}

	reAffirmTestKeyInfo(testKey) {
		getTestKeyInfo(testKey).then((response) => {
			const email = response.email;
			const pinCode = response.pinCode || this.state.initialPin;
			this.setState({
				email,
				pinCode,
				pinLogin: true,
				showCoockieDetectionFrame: false,
			});
		});
	}

	// eslint-disable-next-line complexity
	render() {
		const {isLoginVisible, showAltLogin, altError, isFeide} = this.state;
		if (!isLoginVisible) {
			return null;
		}

		const {error, iframeUrl, initAuth, isInit} = this.props;

		const showIframe = isInit && iframeUrl && !showAltLogin;
		const showError = isInit && error && !showAltLogin;
		const showLoginButtons = (!this.state.passwordLogin || !this.state.pinLogin) && !showAltLogin && !isInit;
		if (showIframe && isFeide) window.location.href = `${iframeUrl}`;
		return (
			<div className="Login">
				<header className="lit-header">
					<h1 className="lit-logo">
						<img
							className="lit-logo__img"
							src={Logo}
							alt="literate logo"
						/>
					</h1>
				</header>
				<div className="lit-wrapper--article text-center">
					<h2>Innlogging</h2>
					{ (!this.state.passwordLogin && !this.state.pinLogin) && (
						<p>
							Du må logge inn før du kan starte testen. Velg om du
							vil logge inn med vanlig BankID, BankID på mobil,
							Epost, Feide eller pinkode.
						</p>
					)}
				
					{(showLoginButtons && 
					!altError &&
					!this.state.requiresVerification &&
						!this.state.passwordLogin &&
						!this.state.pinLogin) && (
							<div className="bank-id-type-selector-wrapper">
								<button
									className="lit-btn lit-btn--small bg-secondary"
									onClick={() => {
										this.setState({
											showCoockieDetectionFrame: true,
										});
										initAuth(BANK_ID, testKey, false);
									}}
								>
									BankID
								</button>
								<button
									className="lit-btn lit-btn--small bg-secondary"
									onClick={() => {
										this.setState({
											showCoockieDetectionFrame: true,
										});
										initAuth(
											BANK_ID_MOBILE,
											testKey,
											false
										);
									}}
								>
									BankID på Mobil
								</button>
								<button
									className="lit-btn lit-btn--small bg-secondary"
									onClick={() => {
										this.setState({
											showCoockieDetectionFrame: true,
											isFeide: true,
										});
										initAuth(FEIDE, testKey, false);
									}}
								>
									Feide
								</button>
							
									<button
									disabled={!this.state.isDistributed}
										className="lit-btn lit-btn--small bg-secondary"
										onClick={() => {
											this.setState({
												passwordLogin: true,
											});
										}}
									>
										Epost
									</button>
							
								
									<button
									disabled={!this.state.isDistributed}
										className="lit-btn lit-btn--small bg-secondary"
										onClick={() => {
											this.reAffirmTestKeyInfo(testKey);
										}}
									>
										Pinkode
									</button>
								
								
							</div>
						)}
					{altError && (
						<div>
							<br />
							<span
								className="error"
								style={{
									color: 'yellow',
									fontSize: '18px',
									overflowWrap: 'breakWord',
								}}
							>
								<span
									style={{
										fontWeight: 'bold',
										color: '#ff6600',
										textShadow: 'black 1px 1px 1px ',
										fontSize: '20px',
										marginRight: '3px',
										overflowWrap: 'break-word',
									}}
								>
									<i>{`Error: `}</i>
								</span>
								<span>{'	 '}</span>
								{altError}
							</span>
						</div>
					)}

					{!altError && this.state.instructions && (
						<div>
							<br />
							<span
								className="error"
								style={{
									color: 'yellow',
									fontSize: '18px',
									overflowWrap: 'breakWord',
								}}
							>
								<span
									style={{
										fontWeight: 'bold',
										color: '#ff6600',
										textShadow: 'black 1px 1px 1px ',
										fontSize: '20px',
										marginRight: '3px',
										overflowWrap: 'break-word',
									}}
								>
									<i>{` `}</i>
								</span>
								<span>{'	 '}</span>
								{this.state.instructions}
							</span>
						</div>
					)}

					<p className="lit-thirdPCsupport" id="result"></p>
					<div className="lit-thrid-party-settings">
						<p
							className="candidate-start-fail__instructions"
							style={{
								color: 'white',
								margin: 'auto',
								textAlign: 'center',
								fontSize: '18px',
							}}
						>
							<span
								id="start-fail-warn-ing"
								style={{justifyContent: 'center'}}
							></span>
							<span style={{color: 'Cyan', marginLeft: '2px'}}>
								<b>
									<span
										id="lit-site-warning"
										style={{marginLeft: '5px'}}
									></span>{' '}
								</b>
							</span>
							<em>
								<span id="lit-site-warning_text"></span>
							</em>
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://www.dysmate.no/advarsel-2/"
							>
								<u style={{color: 'lime'}}>
									<span id="lit-site-settings-instructions"></span>
								</u>
							</a>
							<em>
								<span id="lit-site-warning_text_end"></span>
							</em>
						</p>
					</div>
					{showIframe && !isFeide && (
						<div className="bank-id-iframe-wrapper">
							<iframe
								title="login-frame"
								frameBorder="0"
								id="authFrame"
								scrolling="no"
								src={iframeUrl}
								style={{
									height: '600px',
									width: '100%',
								}}
							/>
						</div>
					)}
					{this.state.passwordLogin && (
						<div className="lit-customlogin-sub-wrapper">
							<CustomLogin />
						</div>
					)}
					{this.state.pinLogin && this.state.email && (
						<div className="lit-customlogin-sub-wrapper">
							<CustomPinLogin
								email={this.state.email}
								pinCode={this.state.pinCode}
							/>
						</div>
					)}
					{showError && <div>{error}</div>}
					{showAltLogin && (
						<div className="alternate-login">
							<p>
								For å logge inn uten BankID eller Feide trenger
								vi å vite hva du heter
							</p>
							<form onSubmit={this.submitAltLogin}>
								<label>
									Fornavn
									<input
										type="text"
										name="firstName"
										id="firstName"
										onChange={this.onAltLoginFieldChange}
									/>
								</label>
								<label>
									Etternavn
									<input
										type="text"
										name="lastName"
										id="lastName"
										onChange={this.onAltLoginFieldChange}
									/>
								</label>
								{altError && (
									<p className="error">{altError}</p>
								)}
								<button
									className="lit-btn lit-btn--small"
									type="submit"
								>
									Logg inn
								</button>
							</form>
						</div>
					)}

					{this.state.showCoockieDetectionFrame && (
						<div>
							<iframe
								src="https://mindmup.github.io/3rdpartycookiecheck/start.html"
								style={{display: 'none'}}
							/>
						</div>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {error, iframeUrl, isInit} = state.auth;

	return {
		error,
		iframeUrl,
		isInit,
	};
};

const mapDispatchToProps = {
	initAuth: initAuthAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
